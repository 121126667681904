import React from "react";
import "./index.scss";

import { useSelector, useDispatch } from "react-redux";
import * as timestampActions from "../../../../actions/timestampActions";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";

import Spinner from "../../../../components/customComponents/Spinner";
import CustomButtonSmall from "../../../../components/customComponents/ButtonSmall";
import CustomInput from "../../../../components/customComponents/CustomInput";
import CustomDropbox from "../../../../components/customComponents/DropBox";
import { FilteredCustomTable } from "../../../../components/customComponents/Table";

const AdvertiserCRM = () => {
    const [crmData, setCrmData] = React.useState();
    const [loadingSpinner, setLoadingSpinner] = React.useState(false);

    const [addCRMVisible, setAddCRMVisible] = React.useState(false);
    const [addCRMSelected, setAddCRMSelected] = React.useState();
    const [addCRMSpinner, setAddCRMSpinner] = React.useState(false);

    const addCRMDivRef = React.useRef();
    const [infoP, setInfoP] = React.useState("");

    const crmFriendlyNameRef = React.useRef();
    const bitrix24InboundRef = React.useRef();
    const bitrix24OutboundRef = React.useRef();
    const leadvertexInboundRef = React.useRef();
    const leadvertexOutboundRef = React.useRef();
    const leadvertexWebmasterID = React.useRef();
    const scaleCRMUrl = React.useRef();
    const scaleCRMToken = React.useRef();
    const scaleCRMSecret = React.useRef();

    const userInfo = useSelector(state => state?.userData);
    const timestamp = useSelector(state => state?.timeStamp?.timeStamp);
    const crmDispatch = useDispatch();

    const dp = CustomDropbox();

    React.useEffect(() => {
        if (!userInfo.userData?.AdvertiserInfo?.ID) return setCrmData({status: "error", data: "ID_INVALID"});
        if (crmData?.status === "error") setCrmData({});
        setLoadingSpinner(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/partners/getAllCRMs`,
            data: {
                BoundAdvertiser: userInfo.userData?.AdvertiserInfo?.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setCrmData({...res.data, timestamp: timestamp});
        }).catch(() => {
            setCrmData({status: "error", data: "SERVER_TIMED_OUT", timestamp: timestamp});
        }).finally(() => {
            setLoadingSpinner(false);
        });
    }, [timestamp]);

    React.useEffect(() => {
        if (!addCRMDivRef.current) return;
        let timeout = setTimeout(() => {
            if (!addCRMDivRef.current) return;
            addCRMDivRef.current.style.overflow = addCRMVisible ? "unset" : "hidden";
        }, addCRMVisible ? 300 : 0);
        return () => clearTimeout(timeout);
    }, [addCRMVisible]);

    const AddCrmScaleCRM = () => {
        setInfoP("");
        if (!crmFriendlyNameRef.current || !scaleCRMUrl.current || !scaleCRMToken.current || !scaleCRMSecret.current) {
            return setInfoP("A general error occured!");
        };
        if (!userInfo.userData?.AdvertiserInfo?.ID) return setInfoP("Advertiser ID invalid!");
        if (!crmFriendlyNameRef.current.value) return setInfoP("Friendly name can't be empty");
        if (!scaleCRMUrl.current.value) return setInfoP("API URL can't be empty");
        if (!scaleCRMToken.current.value) return setInfoP("API Token can't be empty");
        if (!scaleCRMSecret.current.value) return setInfoP("Postback secret can't be empty");

        setAddCRMSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/partners/addCRM`,
            data: {
                CRMName: "scalecrm",
                CRMFriendlyName: crmFriendlyNameRef.current.value,
                CRMData: {
                    inboundURL: scaleCRMUrl.current.value,
                    apiToken: scaleCRMToken.current.value,
                    postbackSecret: scaleCRMSecret.current.value
                },
                advertiserID: userInfo.userData?.AdvertiserInfo?.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (crmFriendlyNameRef.current && bitrix24InboundRef.current && bitrix24OutboundRef.current) {
                    crmFriendlyNameRef.current.value = "";
                    bitrix24InboundRef.current.value = "";
                    bitrix24OutboundRef.current.value = "";
                };
                setAddCRMVisible(false);
            } else {
                return setInfoP("Failed while adding CRM, check your info and try again later!");
            };
        }).catch(() => {
            setInfoP("A server error occured while adding the CRM!");
        }).finally(() => {
            setAddCRMSpinner(false);
            crmDispatch(timestampActions.updateTimestamp());
        });
    };

    const AddCrmBitrix = () => {
        setInfoP("");
        if (!crmFriendlyNameRef.current || !bitrix24InboundRef.current || !bitrix24OutboundRef.current) {
            return setInfoP("A general error occured!");
        };
        if (!userInfo.userData?.AdvertiserInfo?.ID) return setInfoP("Advertiser ID invalid!");
        if (!crmFriendlyNameRef.current.value) return setInfoP("Friendly name can't be empty");
        if (!bitrix24InboundRef.current.value) return setInfoP("Inbound URL can't be empty");
        if (!bitrix24OutboundRef.current.value) return setInfoP("Outbound token can't be empty");

        setAddCRMSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/partners/addCRM`,
            data: {
                CRMName: "bitrix24",
                CRMFriendlyName: crmFriendlyNameRef.current.value,
                CRMData: {
                    inboundURL: bitrix24InboundRef.current.value,
                    outboundToken: bitrix24OutboundRef.current.value
                },
                advertiserID: userInfo.userData?.AdvertiserInfo?.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (crmFriendlyNameRef.current && bitrix24InboundRef.current && bitrix24OutboundRef.current) {
                    crmFriendlyNameRef.current.value = "";
                    bitrix24InboundRef.current.value = "";
                    bitrix24OutboundRef.current.value = "";
                };
                setAddCRMVisible(false);
            } else {
                return setInfoP("Failed while adding CRM, check your info and try again later!");
            };
        }).catch(() => {
            setInfoP("A server error occured while adding the CRM!");
        }).finally(() => {
            setAddCRMSpinner(false);
            crmDispatch(timestampActions.updateTimestamp());
        });
    };

    const AddCrmLeadVertex = () => {
        setInfoP("");
        if (!crmFriendlyNameRef.current || !leadvertexInboundRef.current || !leadvertexOutboundRef.current) {
            return setInfoP("A general error occured!");
        };
        if (!userInfo.userData?.AdvertiserInfo?.ID) return setInfoP("Advertiser ID invalid!");
        if (!crmFriendlyNameRef.current.value) return setInfoP("Friendly name can't be empty");
        if (!leadvertexInboundRef.current.value) return setInfoP("API URL can't be empty");
        if (!leadvertexOutboundRef.current.value) return setInfoP("API token can't be empty");

        setAddCRMSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/partners/addCRM`,
            data: {
                CRMName: "leadvertex",
                CRMFriendlyName: crmFriendlyNameRef.current.value,
                CRMData: {
                    inboundURL: leadvertexInboundRef.current.value,
                    outboundToken: leadvertexOutboundRef.current.value,
                    webmasterID: leadvertexWebmasterID.current.value
                },
                advertiserID: userInfo.userData?.AdvertiserInfo?.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (crmFriendlyNameRef.current && leadvertexInboundRef.current && leadvertexOutboundRef.current) {
                    crmFriendlyNameRef.current.value = "";
                    leadvertexInboundRef.current.value = "";
                    leadvertexOutboundRef.current.value = "";
                };
                setAddCRMVisible(false);
            } else {
                return setInfoP("Failed while adding CRM, check your info and try again later!");
            };
        }).catch(() => {
            setInfoP("A server error occured while adding the CRM!");
        }).finally(() => {
            setAddCRMSpinner(false);
            crmDispatch(timestampActions.updateTimestamp());
        });
    };

    const DeleteCRM = (props) => {
        const [spinner, setSpinner] = React.useState(false);
        const [error, setError] = React.useState("");

        const performDelete = () => {
            setError("");
            if (!userInfo.userData?.AdvertiserInfo?.ID) return setError("Advertiser ID invalid!");
            
            setSpinner(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/partners/deleteCRM`,
                data: {
                    ID: props.id,
                    advertiserID: userInfo.userData?.AdvertiserInfo?.ID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    props.c().then(() => {
                        if (props.onUpdate) props.onUpdate();
                    });
                } else {
                    setError("Error while deleting CRM, maybe in use?");
                };
            }).catch(() => {
                setError("Server timed out!");
            }).finally(() => {
                setSpinner(false);
            });
        };

        if (spinner) return <div className="route__advertiserCRM__delete">
            <Spinner />
        </div>
        if (error) return <div className="route__advertiserCRM__delete">
            <p>There was an error while deleting CRM</p>
            <p>{error}</p>
            <CustomButtonSmall value="Close" onClick={props.c} />
        </div>
        return <div className="route__advertiserCRM__delete">
            <p>Are you sure that you want to delete this CRM?</p>
            <p>NOTE: This will affect ALL offers that are connected with this CRM.</p>
            <CustomButtonSmall value="Yes" onClick={performDelete} style={{backgroundColor: "rgb(0, 163, 255)", color: "white"}} />
            <CustomButtonSmall value="No" onClick={props.c} />
        </div>
    };

    const EditCRM = (props) => {
        const [spinner, setSpinner] = React.useState(false);
        const [error, setError] = React.useState("");

        const friendlyNameRef = React.useRef();

        const b24InboundRef = React.useRef();
        const b24OutboundRef = React.useRef();

        const vertexInboundRef = React.useRef();
        const vertexOutboundRef = React.useRef();
        const vertexWebmasterID = React.useRef();

        const scalecrmUrl = React.useRef();
        const scalecrmToken = React.useRef();
        const scalecrmSecret = React.useRef();

        const performEdit = () => {
            setError("");
            if (!userInfo.userData?.AdvertiserInfo?.ID) return setInfoP("Advertiser ID invalid!");

            if (props.data?.name === "scalecrm") {
                if (!friendlyNameRef.current.value) return setError("Friendly name can't be empty");
                if (!scalecrmUrl.current.value) return setError("API URL can't be empty");
                if (!scalecrmToken.current.value) return setError("API token can't be empty");
                if (!scalecrmSecret.current.value) return setError("Postback secret can't be empty");

                let toSend = {
                    ID: props.data.ID,
                    CRMFriendlyName: friendlyNameRef.current.value,
                    CRMData: {
                        inboundURL: scalecrmUrl.current.value,
                        apiToken: scalecrmToken.current.value,
                        postbackSecret: scalecrmSecret.current.value
                    },
                    advertiserID: userInfo.userData?.AdvertiserInfo?.ID
                };
                setSpinner(true);
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/partners/editCRM`,
                    data: toSend,
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        props.c().then(() => {
                            if (props.onUpdate) props.onUpdate({
                                ...props.data,
                                friendlyName: toSend.CRMFriendlyName,
                                data: toSend.CRMData
                            });
                        });
                    } else {
                        setError("Error while editing CRM, check your info and try again later");
                    };
                }).catch(() => {
                    setError("Server timed out!");
                }).finally(() => {
                    setSpinner(false);
                });
            } else if (props.data?.name === "bitrix24") {
                if (!friendlyNameRef.current.value) return setError("Friendly name can't be empty");
                if (!b24InboundRef.current.value) return setError("Inbound URL can't be empty");
                if (!b24OutboundRef.current.value) return setError("Outbound token can't be empty");

                let toSend = {
                    ID: props.data.ID,
                    CRMFriendlyName: friendlyNameRef.current.value,
                    CRMData: {
                        inboundURL: b24InboundRef.current.value,
                        outboundToken: b24OutboundRef.current.value
                    },
                    advertiserID: userInfo.userData?.AdvertiserInfo?.ID
                };
                setSpinner(true);
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/partners/editCRM`,
                    data: toSend,
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        props.c().then(() => {
                            if (props.onUpdate) props.onUpdate({
                                ...props.data,
                                friendlyName: toSend.CRMFriendlyName,
                                data: toSend.CRMData
                            });
                        });
                    } else {
                        setError("Error while editing CRM, check your info and try again later");
                    };
                }).catch(() => {
                    setError("Server timed out!");
                }).finally(() => {
                    setSpinner(false);
                });
            } else if (props.data?.name === "leadvertex") {
                if (!friendlyNameRef.current.value) return setError("Friendly name can't be empty");
                if (!vertexInboundRef.current.value) return setError("Inbound URL can't be empty");
                if (!vertexOutboundRef.current.value) return setError("Outbound token can't be empty");

                let toSend = {
                    ID: props.data.ID,
                    CRMFriendlyName: friendlyNameRef.current.value,
                    CRMData: {
                        inboundURL: vertexInboundRef.current.value,
                        outboundToken: vertexOutboundRef.current.value,
                        webmasterID: vertexWebmasterID.current.value
                    },
                    advertiserID: userInfo.userData?.AdvertiserInfo?.ID
                };
                setSpinner(true);
                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/partners/editCRM`,
                    data: toSend,
                    ...backendModule.axiosConfig
                }).then(res => {
                    if (res.data.status === "ok") {
                        props.c().then(() => {
                            if (props.onUpdate) props.onUpdate({
                                ...props.data,
                                friendlyName: toSend.CRMFriendlyName,
                                data: toSend.CRMData
                            });
                        });
                    } else {
                        setError("Error while editing CRM, check your info and try again later");
                    };
                }).catch(() => {
                    setError("Server timed out!");
                }).finally(() => {
                    setSpinner(false);
                });
            };
        };

        if (spinner) return <div className="route__advertiserCRM__edit">
            <Spinner />
        </div>
        if (error) return <div className="route__advertiserCRM__edit">
            <p>There was an error while editing CRM</p>
            <p>{error}</p>
            <CustomButtonSmall value="Close" onClick={props.c} />
        </div>
        return <div className="route__advertiserCRM__edit">
            <div className="route__advertiserCRM__edit__wrap">
                <span>Friendly name:</span>
                <CustomInput ref={friendlyNameRef} defaultValue={props.data?.friendlyName} placeholder1="Friendly name" />
            </div>
            {props.data?.name === "scalecrm" && <>
                <div className="route__advertiserCRM__edit__wrap">
                    <span>API URL:</span>
                    <CustomInput ref={scalecrmUrl} defaultValue={props.data?.data?.inboundURL} placeholder1="API URL" />
                </div>
                <div className="route__advertiserCRM__edit__wrap">
                    <span>API token:</span>
                    <CustomInput ref={scalecrmToken} defaultValue={props.data?.data?.apiToken} placeholder1="API token" />
                </div>
                <div className="route__advertiserCRM__edit__wrap">
                    <span>Postback secret:</span>
                    <CustomInput ref={scalecrmSecret} defaultValue={props.data?.data?.postbackSecret} placeholder1="Postback secret" />
                </div>
            </>}
            {props.data?.name === "bitrix24" && <>
                <div className="route__advertiserCRM__edit__wrap">
                    <span>Inbound URL:</span>
                    <CustomInput ref={b24InboundRef} defaultValue={props.data?.data?.inboundURL} placeholder1="Inbound URL (webhook)" />
                </div>
                <div className="route__advertiserCRM__edit__wrap">
                    <span>Outbound token:</span>
                    <CustomInput ref={b24OutboundRef} defaultValue={props.data?.data?.outboundToken} placeholder1="Outbound token (webhook)" />
                </div>
            </>}
            {props.data?.name === "leadvertex" && <>
                <div className="route__advertiserCRM__edit__wrap">
                    <span>API URL:</span>
                    <CustomInput ref={vertexInboundRef} defaultValue={props.data?.data?.inboundURL} placeholder1="API URL" />
                </div>
                <div className="route__advertiserCRM__edit__wrap">
                    <span>API token:</span>
                    <CustomInput ref={vertexOutboundRef} defaultValue={props.data?.data?.outboundToken} placeholder1="API token" />
                </div>
                <div className="route__advertiserCRM__edit__wrap">
                    <span>Webmaster ID (optional):</span>
                    <CustomInput ref={vertexWebmasterID} defaultValue={props.data?.data?.webmasterID} placeholder1="Webmaster ID" />
                </div>
            </>}
            <CustomButtonSmall value="Save" onClick={performEdit} style={{backgroundColor: "rgb(0, 163, 255)", color: "white"}} />
            <CustomButtonSmall value="Cancel" onClick={props.c} />
        </div>
    };

    const GetCRMIntegrationLink = (props) => {
        let [info, setInfo] = React.useState("");

        React.useEffect(() => {
            if (!userInfo.userData?.AdvertiserInfo?.ID) return setInfo("Advertiser ID invalid!");
            
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/partners/getCRMIntegrationLink`,
                data: {
                    advertiserID: userInfo.userData?.AdvertiserInfo?.ID,
                    ID: props.data.ID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    setInfo(res.data.data);
                } else {
                    setInfo("Failed to generate integration link\nContact your assigned manager!");
                }
            }).catch(() => setInfo("Server timed out!"));
        }, []);

        return <div className="route__advertiserCRM__integration">
            {info ? <>
                {Array.isArray(info) ? info.map((t, tIndex) => <p className={tIndex === info.length-1 ? "route__advertiserCRM__integration__link" : ""}>{t}</p>) : <p>{info}</p>}
                <CustomButtonSmall value="Close" onClick={props.c} />
            </> : <Spinner />}
        </div>
    };

    if (userInfo.userData?.AdvertiserInfo?.Flags?.isVerified === false) {
        return <div className="domains"><div className="dashboard__verify" style={{ margin: 'auto' }}>
            <p>Your account has not been verified</p>
        </div></div>
    };
    return <div className="route__advertiserCRM">
        <button  className="route__adminDomains__addDomainBtn" onClick={() => setAddCRMVisible(!addCRMVisible)} >Add CRM <img src="/images/plusImageWhite.png"/></button>
        <div ref={addCRMDivRef} className={`route__advertiserCRM__addCRM ${addCRMVisible ? "route__advertiserCRM__addCRM--active" : ""}`}>
            <Spinner style={{
                opacity: addCRMSpinner ? 0.7 : 0
            }} />
            <div className="route__advertiserCRM__addCRM__wrap">
                <span>CRM type</span><dp.DropBox data={[
                    {name: "bitrix24", value: "bitrix24"},
                    {name: "LeadVertex", value: "leadvertex"},
                    {name: "Scale CRM", value: "scalecrm"},
                ]} selected={0} onChange={() => setAddCRMSelected(dp.getSelectedItem().value)} />
            </div>
            {addCRMSelected === "scalecrm" && <>
                <div className="route__advertiserCRM__addCRM__wrap">
                    <span>Friendly name:</span>
                    <CustomInput ref={crmFriendlyNameRef} placeholder1="Friendly name" />
                </div>
                <div className="route__advertiserCRM__addCRM__wrap">
                    <span>Inbound URL:</span>
                    <CustomInput ref={scaleCRMUrl} placeholder1="Inbound URL (API)" />
                </div>
                <div className="route__advertiserCRM__addCRM__wrap">
                    <span>API Token:</span>
                    <CustomInput ref={scaleCRMToken} placeholder1="API Token" />
                </div>
                <div className="route__advertiserCRM__addCRM__wrap">
                    <span>Postback secret:</span>
                    <CustomInput ref={scaleCRMSecret} placeholder1="Postback secret" />
                </div>
                <div className="route__advertiserCRM__addCRM__wrap">
                    <CustomButtonSmall value="Save" onClick={AddCrmScaleCRM} style={{backgroundColor: "rgb(0, 163, 255)", color: "white"}} />
                    <CustomButtonSmall value="Cancel" onClick={() => setAddCRMVisible(false)} />
                </div>
            </>}
            {addCRMSelected === "bitrix24" && <>
                <div className="route__advertiserCRM__addCRM__wrap">
                    <span>Friendly name:</span>
                    <CustomInput ref={crmFriendlyNameRef} placeholder1="Friendly name" />
                </div>
                <div className="route__advertiserCRM__addCRM__wrap">
                    <span>Inbound URL:</span>
                    <CustomInput ref={bitrix24InboundRef} placeholder1="Inbound URL (Webhook)" />
                </div>
                <div className="route__advertiserCRM__addCRM__wrap">
                    <span>Outbound token:</span>
                    <CustomInput ref={bitrix24OutboundRef} placeholder1="Outbound Token (Webhook)" />
                </div>
                <div className="route__advertiserCRM__addCRM__wrap">
                    <CustomButtonSmall value="Save" onClick={AddCrmBitrix} style={{backgroundColor: "rgb(0, 163, 255)", color: "white"}} />
                    <CustomButtonSmall value="Cancel" onClick={() => setAddCRMVisible(false)} />
                </div>
            </>}

            {addCRMSelected === "leadvertex" && <>
                <div className="route__advertiserCRM__addCRM__wrap">
                    <span>Friendly name:</span>
                    <CustomInput ref={crmFriendlyNameRef} placeholder1="Friendly name" />
                </div>
                <div className="route__advertiserCRM__addCRM__wrap">
                    <span>API URL:</span>
                    <CustomInput ref={leadvertexInboundRef} placeholder1="API URL" />
                </div>
                <div className="route__advertiserCRM__addCRM__wrap">
                    <span>API token:</span>
                    <CustomInput ref={leadvertexOutboundRef} placeholder1="API Token" />
                </div>
                <div className="route__advertiserCRM__addCRM__wrap">
                    <span>Webmaster ID (optional):</span>
                    <CustomInput ref={leadvertexWebmasterID} placeholder1="API Token" />
                </div>
                <div className="route__advertiserCRM__addCRM__wrap">
                    <CustomButtonSmall value="Save" onClick={AddCrmLeadVertex} style={{backgroundColor: "rgb(0, 163, 255)", color: "white"}} />
                    <CustomButtonSmall value="Cancel" onClick={() => setAddCRMVisible(false)} />
                </div>
            </>}
            <p className="route__advertiserCRM__addCRM__infoP">{infoP}</p>
        </div>
        <FilteredCustomTable headers={["CRM Name", "CRM Type"]} data={(()=>{
            let tmp = [];

            if (loadingSpinner || !crmData) tmp.push([{keyID: "customSpinner", type: "custom", data: <Spinner />}]);
            if (!crmData) return tmp;
            if (crmData?.status === "error") {
                tmp.push([{ keyID: `errorText${timestamp}`, type: "custom", data: <div className="noDataTable" style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <p>There was an error while fetching data</p>
                    <CustomButtonSmall onClick={() => {
                        setCrmData(null);
                        crmDispatch(timestampActions.updateTimestamp());
                    }} value="Retry" style={{marginTop: "20px"}} />
                </div> }])
            } else if (crmData?.status === "ok") {
                crmData.data.forEach((curCrm, curCrmIndex) => {
                    if (curCrm.name === "bitrix24" || curCrm.name === "scalecrm") {
                        tmp.push([
                            {keyID: curCrm.ID, type: "text", text: curCrm.friendlyName},
                            {keyID: curCrm.ID, type: "text", text: curCrm.name},
                            {keyID: curCrm.ID, type: "groupNewline", group: [
                                {keyID: curCrm.ID + timestamp, type: "button", text: "Edit", triggerDropdown: true, triggerData: (c) => <EditCRM c={c} data={curCrm} onUpdate={(newData) => {
                                    setCrmData({
                                        ...crmData,
                                        data: [
                                            ...crmData.data.filter((e, eI) => eI < curCrmIndex),
                                            newData,
                                            ...crmData.data.filter((e, eI) => eI > curCrmIndex),
                                        ]
                                    });
                                }} />},
                                {keyID: curCrm.ID, type: "button", text: "Delete", triggerDropdown: true, triggerData: (c) => <DeleteCRM id={curCrm.ID} onUpdate={() => {
                                    setCrmData({
                                        ...crmData,
                                        data: crmData.data.filter(e => e.ID !== curCrm.ID)
                                    });
                                }} c={c} />},
                                {keyID: curCrm.ID, type: "button", text: "Get handler URL", triggerDropdown: true, triggerData: c => <GetCRMIntegrationLink data={curCrm} c={c} />}
                            ]}
                        ]);
                    } else if (curCrm.name === "leadvertex") {
                        tmp.push([
                            {keyID: curCrm.ID, type: "text", text: curCrm.friendlyName},
                            {keyID: curCrm.ID, type: "text", text: curCrm.name},
                            {keyID: curCrm.ID, type: "groupNewline", group: [
                                {keyID: curCrm.ID + timestamp, type: "button", text: "Edit", triggerDropdown: true, triggerData: (c) => <EditCRM c={c} data={curCrm} onUpdate={(newData) => {
                                    setCrmData({
                                        ...crmData,
                                        data: [
                                            ...crmData.data.filter((e, eI) => eI < curCrmIndex),
                                            newData,
                                            ...crmData.data.filter((e, eI) => eI > curCrmIndex),
                                        ]
                                    });
                                }} />},
                                {keyID: curCrm.ID, type: "button", text: "Delete", triggerDropdown: true, triggerData: (c) => <DeleteCRM id={curCrm.ID} onUpdate={() => {
                                    setCrmData({
                                        ...crmData,
                                        data: crmData.data.filter(e => e.ID !== curCrm.ID)
                                    });
                                }} c={c} />},
                                {keyID: curCrm.ID, type: "button", text: "Get postback URL", triggerDropdown: true, triggerData: c => <GetCRMIntegrationLink data={curCrm} c={c} />}
                            ]}
                        ]);
                    };
                })
            };

            if (tmp.length === 0) tmp.push([{ keyID: "customText", type: "custom", data: <div className="noDataTable">Currently no data to display</div> }])
            return tmp;
        })()} />
    </div>
};

export default AdvertiserCRM