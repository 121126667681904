import "./index.scss";
import axios from "axios";
import * as backendModule from '../../modules/backendModule'
import AnimateModule from '../../modules/animateModule'
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();
    const animateNavigate = to => {
        AnimateModule(navigate, to, document.querySelector(".component__contentWrapper"));
    };
    const sendData = (e) => {
        e.preventDefault()
        let inputs = e.target.querySelectorAll('input')
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/email/contact`,
            data: {
                Name: inputs[0].value,
                EMail: inputs[1].value,
                Message: e.target.querySelector('textarea').value
            },
            ...backendModule.axiosConfig
        }).then((e) => {
            document.querySelector('.component__footer__contact__form__msg').style.opacity = '1'
            inputs[0].value = ''
            inputs[1].value = ''
            e.target.querySelector('textarea').value = ''
        }).catch((e) => {
        })
    }

    return <div className="component__footer">
        <img className="component__footer__image"  src="/images/landing/Footer.png">

        </img>
        <div className="component__footer__contact" >
            <div className="component__footer__contact__content" >
                <h2>Contact us</h2>
                <div>
                    <div>
                        <h6>Company name</h6>

                        <p>  SCALE LEAD D.O.O. </p>
                    </div>
                    
                    <div>
                        <h6>TAX (PIB):</h6>
                        <p>114553755</p>
                    </div>
                </div>

                <div>
                    <div>
                        <h6>Email</h6>
                        <p>info@scale-lead.com</p>
                    </div>
                    <div>
                        <h6>Address</h6>
                        <p>BEOGRAD (STARI GRAD) , KNEZ MIHAILA 3, SERBIA </p>
                    </div>
                </div>


            </div>
            <form className="component__footer__contact__form" onSubmit={(e) => { sendData(e) }} >
                <h2>Get in touch</h2>
                <input type='text' required placeholder="Your name" />
                <input type='email' required placeholder="Your email" />
                <textarea required placeholder="Your message" ></textarea>
                <button type="submit" >Get in touch</button>
                <p className="component__footer__contact__form__msg"  > Your form has been successfully submitted </p>
            </form>
        </div>
        <div className="component__footer__logos" >
            <img onClick={()=>{animateNavigate('/')}} src="/images/logoNewWhite.svg" style={{'width':'190px'}} ></img>
            <div className="component__footer__logos__social" >

                <a href="https://www.facebook.com/scalelead" target='_blank' ><img src="/images/landing/fb.png" /></a>
                <a href="https://www.linkedin.com/company/scalelead" target='_blank'><img src="/images/landing/ln.png" /></a>
                <a href="https://www.instagram.com/scalelead/" target='_blank'><img src="/images/landing/insta.png" /></a>


            </div>
        </div>
    </div>
};

export default Footer;