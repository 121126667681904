import React from "react";
import "./index.scss";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as backendModule from "../../../../modules/backendModule";
import animateModule from "../../../../modules/animateModule";

import { FilteredCustomTable } from "../../../../components/customComponents/Table";
import Button from "../../../../components/customComponents/Button";
import CustomInput from "../../../../components/customComponents/CustomInput";
import Spinner from "../../../../components/customComponents/Spinner";
import CustomRadio from "../../../../components/customComponents/Radio";
import Dropdown from "../../../../components/customComponents/Dropdown";

const ApiTokens = props => {
    const [add, setAdd] = React.useState(false);
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);
    const [flags, setFlags] = React.useState(undefined);

    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();

    const curTimestampSelector = useSelector(state => state?.timestamp?.timestamp ?? null);
    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const getFlags = () => {
        setFlags(null);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/apiTokens/getAllAvailableFlags`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setFlags(res.data);
            } else {
                setFlags({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setFlags({status: "error", data: "SERVER_ERROR"});
        });
    };

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/apiTokens/getAllApiTokens`,
            data: {
                offset: paginationOffset.current,
                filters: [
                    ...filters,
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };

                return setData({ ...res.data, timestamp: Date.now() });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/apiTokens/getAllApiTokens`,
            data: {
                offset: 0,
                filters: [
                    ...filters,
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ], timestamp: Date.now()
                    };
                });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch { };

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}></div>;
    };

    React.useEffect(() => {
        getFlags();
    }, []);

    React.useEffect(() => {
        getData();
    }, [curTimestampSelector, filters]);

    return <div className="route__adminApiTokens">
        <button onClick={() => setAdd(!add)} class="route__adminDomains__addDomainBtn">Add token<img src="/images/plusImageWhite.png" /></button>
        <AddAPIToken isActive={add} onClose={() => {setAdd(false); getData();}} />
        <FilteredCustomTable
            headers={["ID", "Name", "Date added"]}
            filterCB={f => setFilters(f)}
            filters={[
                {name: "ID", friendlyName: "ID", type: "string"},
                {name: "Name", friendlyName: "Name", type: "string"},
            ]}
            data={(()=>{
                if (!data || !flags) return [[{keyID: "noData-spinner", type: "spinner"}]];
                if (data.status === "error") return [[{keyID: "noData-error", type: "custom", data: <p>There was an error while fetching api tokens</p>}]];
                if (flags.status === "error") return [[{keyID: "noData-error", type: "custom", data: <p>There was an error while fetching api flags</p>}]];

                let final = data.data.map(elem => {
                    return [
                        {keyID: elem.ID, type: "text", text: elem.ID},
                        {keyID: elem.ID, type: "text", text: elem.Name},
                        {keyID: elem.ID, type: "text", text: new Date(elem.createdAt).toLocaleDateString()},
                        {keyID: elem.ID, type: "groupNewline", group: [
                            {keyID: elem.ID, type: "custom", data: <p className="route__adminApiTokens__roles">
                                <span className="route__adminApiTokens__roles__roleName">Roles:</span>
                                {Object.keys(elem.Flags).filter(key => elem.Flags[key]).map(key => {
                                    let foundFlag = flags.data.find(t => t.name === key);
                                    if (!foundFlag) return null;
                                    return <span className="route__adminApiTokens__roles__roleValue">{foundFlag.friendlyName}</span>
                                })}
                            </p>}
                        ]},
                        {keyID: elem.ID, type: "groupNewline", group: [
                            {keyID: elem.ID, type: "button", text: "Go to advertiser account", onClick: () => animateNavigate(`/dashboard/admin-users?ID=${encodeURI(elem.UserID)}`) },
                            {keyID: elem.ID, type: "button", text: "View token", triggerDropdown: true, triggerData: () => <ViewApiToken data={elem} />},
                            {keyID: elem.ID, type: "button", text: "Remove token", triggerDropdown: true, triggerData: c => <RemoveAPIToken c={c} data={elem} onChange={getData} />}
                        ]}
                    ];
                });
                if (final.length === 0) final.push([{keyID: "noData-text", type: "custom", data: <p>There is nothing to show.</p>}]);
                if (secondarySpinner) final.push([{keyID: "data-paginationSpinner", type: "spinner"}]);
                if (canPaginate) final.push([{keyID: "data-pagination", type: "custom", data: <PaginationData />}]);

                return final;
            })()}
        />
    </div>
};

const ViewApiToken = (props) => {
    const [token, setToken] = React.useState();

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/apiTokens/getToken`,
            data: {
                ID: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setToken(res.data);
            } else {
                setToken({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setToken({status: "error", data: "SERVER_ERROR"});
        });
    }, []);

    if (!token) return <div className="route__adminApiTokens__viewToken">
        <Spinner />
    </div>
    if (token.status === "error") return <div className="route__adminApiTokens__viewToken">
        <p>Error while fetching token!</p>
    </div>
    return <div className="route__adminApiTokens__viewToken">
        <p>{token.data}</p>
    </div>
};

const RemoveAPIToken = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [finalData, setFinalData] = React.useState("");

    const removeToken = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/apiTokens/removeApiToken`,
            data: {
                ID: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.c().then(() => {
                    props.onChange && props.onChange();
                });
            } else {
                setSpinner(false);
                setFinalData("Error while removing the API token!");
            };
        }).catch(() => {
            setSpinner(false);
            setFinalData("Server timed out!");
        });
    }

    if (spinner) return <div style={{position: "relative"}}>
        <Spinner />
    </div>
    if (finalData) return <div>
        <p style={{marginBottom: "10px"}}>{finalData}</p>
        <Button value="Close" onClick={props.c} />
    </div>
    return <div>
        <p>Are you sure?</p>
        <p>This action is irreversible!</p>

        <div style={{display: "flex", alignItems: "center", marginTop: "20px"}}>
            <Button value="Remove" style={{marginRight: "10px"}} accent="#00A3FF" onClick={removeToken} />
            <Button value="Close" onClick={props.c} />
        </div>
    </div>
};

const AddAPIToken = (props) => {
    const [flags, setFlags] = React.useState(undefined);
    const [users, setUsers] = React.useState(undefined);
    const [selectedUser, setSelectedUser] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    const [finalFlags, setFinalFlags] = React.useState({});
    const [infoP, setInfoP] = React.useState("");

    const themeSelector = useSelector(state => state?.theme?.theme ?? "light");
    const nameRef = React.useRef();

    const addToken = () => {
        setInfoP("");

        let data = {
            Name: nameRef.current.value,
            UserID: selectedUser,
            Flags: finalFlags
        };

        if (!data.Name) return setInfoP("Name can't be empty");
        if (!data.UserID) return setInfoP("User can't be empty");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/apiTokens/addApiToken`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                nameRef.current.value = "";
                setFlags(undefined);
                setUsers(undefined);
                setSelectedUser(undefined);
                setFinalFlags({});
                props.onClose();
            } else {
                setInfoP("Error while creating API token");
            };
        }).catch(() => {
            setInfoP("Server timed out");
        }).finally(() => {
            setSpinner(false);
        });
    };

    const getFlags = () => {
        setFlags(null);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/apiTokens/getAllAvailableFlags`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setFlags(res.data);
            } else {
                setFlags({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setFlags({status: "error", data: "SERVER_ERROR"});
        });
    };

    const getUsers = () => {
        setUsers(null);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                paginationOffset: 0,
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setUsers({
                    status: "ok", data: [
                        ...(users?.data ?? []),
                        ...res.data.data?.DBData
                    ]
                });
            } else {
                setUsers({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setUsers({status: "error", data: "SERVER_ERROR"});
        });
    };

    React.useEffect(() => {
        if (props.isActive && flags === undefined) getFlags();
        if (props.isActive && users === undefined) getUsers();
    }, [props.isActive]);

    return <div className={`route__adminApiTokens__addToken ${props.isActive ? "route__adminApiTokens__addToken--active" : ""}`}>
        <div className="route__adminApiTokens__addToken__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none"
        }} onClick={e => spinner && e.stopPropagation()}>
            <Spinner />
        </div>

        {(flags && users) ? <>
            {(flags.status === "ok" && users.status === "ok") ? <>
                <h3 style={{marginBottom: "20px"}}>Add token</h3>
                <CustomInput placeholder1="Token name" ref={nameRef} />
                <div style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                    <p style={{marginRight: "20px"}}>User </p> <Dropdown theme={themeSelector} data={users.data.map(usr => {
                        return {name: usr.Username, value: usr.ID};
                    })} onChange={e => setSelectedUser(e?.value)} />
                </div>

                <br />
                {flags.data.map(flg => {
                    return <p className="route__adminApiTokens__addToken__flag">{flg.friendlyName} <CustomRadio selected={!!finalFlags[flg.name]} valueyes={<p>Allow</p>} valueno={<p>Disallow</p>} functionYes={() => setFinalFlags({...finalFlags, [flg.name]: true})} functionNo={() => setFinalFlags({...finalFlags, [flg.name]: false})}  /></p>
                })}

                <br />
                <Button value="Save" accent="#00A3FF" style={{marginTop: "20px"}} onClick={addToken} />
                {infoP && <p className="route__adminApiTokens__addToken__infoP">{infoP}</p>}
            </> : <p>There was an error while getting {flags.status === "error" ? "flag" : "users"} data!</p>}
        </> : <Spinner />}
    </div>
};

export default ApiTokens;